@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

:root {
	--dark-color-a: #667aff;
	--dark-color-b: #7386ff;
	--light-color: #e6e9ff;
	--success-color: #5cb85c;
	--error-color: #d9534f;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	/* background: var(--light-color); */
	margin: 10px;
}

.msgcol{height: calc(100vh - 75px); overflow-y: scroll;margin-right: -26px;}
.msgcol2{margin-right: -12px;height: calc(100vh - 70px);}
.msgcol>div{max-width: 100%;}
.phone .msgcol{
	height: calc(100vh - 88px);
    overflow-y: scroll;
    margin-right: 0;
}
p{font-size: 13px; line-height: 1.3; margin-bottom: 5px;}
.title{font-size: 13px; font-weight: bold;}
.alert{color: #000}
.thin-scroll{overflow: hidden;}
.login-form>form{max-width: 500px; margin: auto; margin-top: 40vh;}
.phone{display: none;}
.top-bar button{width:24%}
.bottom-bar button{width:48%}
@media only screen and (max-width: 600px) {
	.phone{display: block;}
	.web{display: none;}
	.msgcol2, .msgcol{
		margin-right: 0;
	}
}